
<template>
    <div class="container-fluid main-color">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">Increment Approval</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-lg-12">
          <place-holder v-if="loading"></place-holder>
          <div class="card">
            <div v-if="!loading" class="card-body">

                <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="programDetail.program_name"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="programDetail.batch_name"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="programDetail.level"
                        disabled
                      />
                    </div>
                  </div>
                  </div>

                  <div class="col-md-5">
                  <div class="row mb-3">
                    <label for="name" class="col-md-4 form-label">
                      Effective Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <input
                        type="date"
                        disabled
                        v-model="programDetail.effective_date"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

                            <!--------------------  Approvement State  ---------------------->
              <div class="mt-3 controllingParent">
                <div class="d-flex">

                  <div v-for="(item, index) in assessorList" :key="index">
                    <div
                      class="title me-5"
                      :class="item.is_active == 1 ? ' fw-bold active-title ' : ''"
                      @click="handleCurrentActive(item)"
                    >
                    <!-- :class="
                        item.active_status == 1 ? ' fw-bold active-title ' : ''
                      "
                    @click="clickNavigation(item)" -->

                      {{ item.name }}
                    </div>
                  </div>
                  </div>

                  <div class="mt-3 overflowTable">
                  <table class="table" style="min-width: 1500px; overflow: scroll;">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Employee Name</th>
                        <th>Employee ID</th>
                        <th>Joined Date</th>
                        <th>BU/BR/DIV</th>
                        <th>Grade</th>
                        <!-- <th v-if="currentAssessor.name == 'Related COO'">
                          Related COO
                        </th> -->
                        <th>Status</th>
                        <th>Remark</th>
                        <th>HR Remark</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>

                      <tr v-for="(item, index) in assessorDetails">
                        <td>{{ ++index }}</td>
                        <td>{{ item.emp_name }}</td>
                        <td>{{ item.emp_id }}</td>
                        <td>{{ item.joined_date }}</td>
                        <td>{{ item.bubrdiv_name }}</td>
                        <td>{{ item.grade }}</td>
                        <!-- <td v-if="currentAssessor.name == 'Related COO'">
                          {{ item.r_coo_name }}
                        </td> -->

                        <td v-if="activeBar?.name == 'Program Officer'">
                          <!-- {{ JSON.stringify(currentActive) + " __ " + JSON.stringify(activeBar) }} -->
                          <v-select
                          class="selectWidth"
                          :options="statusOptions"
                          label="name"
                          :clearable="false"
                          v-model="item.p_o_status"
                          :disabled="(
                                  !(currentActive[index - 1]?.name === activeBar?.name &&
                                  currentActive[index - 1]?.emp_id === activeBar?.emp_id) 
                              )"
                          >

                          </v-select>
                        </td>
                        <td v-if="activeBar?.name == 'Program Officer'">
                          <textarea
                              v-if="item.p_o_status?.value ==3 || item.p_o_status?.value == 4"
                              v-model="item.p_o_remarks"
                          :disabled="(
                                  !(currentActive[index - 1]?.name === activeBar?.name &&
                                  currentActive[index - 1]?.emp_id === activeBar?.emp_id) 
                              )"
                              >
                            </textarea>
                            <!-- :disabled="!currentActive?.some(c => c?.name === activeBar?.name && c?.emp_id === activeBar?.emp_id)" -->

                        </td>

                        <td v-if="activeBar?.name == 'Department Head'">
                          <v-select
                          class="selectWidth"
                          :options="statusOptions"
                          label="name"
                          :clearable="false"
                          v-model="item.d_h_status"
                          :disabled="(
                                  !(currentActive[index - 1]?.name === activeBar?.name &&
                                  currentActive[index - 1]?.emp_id === activeBar?.emp_id) 
                                  || !item.p_o_status
                              )"
                          >
                          <!-- :disabled="!currentActive?.some(c => c?.name === activeBar?.name && c?.emp_id === activeBar?.emp_id) || 
                                     (!item.p_o_status)" -->

                          </v-select>
                        </td>
                        <td v-if="activeBar?.name == 'Department Head'">
                          <textarea
                              v-if="item.d_h_status?.value ==3 || item.d_h_status?.value == 4"
                              v-model="item.d_h_remarks"
                              :disabled="(
                                  !(currentActive[index - 1]?.name === activeBar?.name &&
                                  currentActive[index - 1]?.emp_id === activeBar?.emp_id) 
                                  || !item.p_o_status
                              )"
                            >
                            </textarea>
                            <!-- :disabled="!currentActive?.some(c => c?.name === activeBar?.name && c?.emp_id === activeBar?.emp_id) || 
                            (!item.p_o_status)" -->
                        </td>

                        <td v-if="activeBar?.name == 'GM AGM'">
                          <v-select
                          class="selectWidth"
                          :options="statusOptions"
                          label="name"
                          :clearable="false"
                          v-model="item.gm_agm_status"
                          :disabled="(
                                  !(currentActive[index - 1]?.name === activeBar?.name &&
                                  currentActive[index - 1]?.emp_id === activeBar?.emp_id) 
                                  || !item.d_h_status
                              )"
                          >
                          <!-- :disabled="!currentActive?.some(c => c?.name === activeBar?.name && c?.emp_id === activeBar?.emp_id) || (!item.d_h_status)" -->

                          </v-select>
                        </td>
                        <td v-if="activeBar?.name == 'GM AGM'">
                          <textarea
                              v-if="item.gm_agm_status?.value ==3 || item.gm_agm_status?.value == 4"
                              v-model="item.gm_agm_remarks"
                              :disabled="(
                                  !(currentActive[index - 1]?.name === activeBar?.name &&
                                  currentActive[index - 1]?.emp_id === activeBar?.emp_id) 
                                  || !item.d_h_status
                              )"
                            >
                            </textarea>
                            <!-- :disabled="!currentActive?.some(c => c?.name === activeBar?.name && c?.emp_id === activeBar?.emp_id) || (!item.d_h_status)" -->

                        </td>

                        <td v-if="activeBar?.name == 'Related COO'">
                          <v-select
                          class="selectWidth"
                          :options="statusOptions"
                          label="name"
                          :clearable="false"
                          v-model="item.r_coo_status"
                          :disabled="(
                                  !(currentActive[index - 1]?.name === activeBar?.name &&
                                  currentActive[index - 1]?.emp_id === item.r_coo_id) 
                                  || !item.gm_agm_status
                              )"
                          >

                          <!-- :disabled="!currentActive?.some(c => c?.name === activeBar?.name && item.r_coo_id == c?.emp_id ) ||
                                     (!item.gm_agm_status)" -->
                          </v-select>
                        </td>
                        <td v-if="activeBar?.name == 'Related COO'">
                          <textarea
                              v-if="item.r_coo_status?.value ==3 || item.r_coo_status?.value == 4"
                              v-model="item.r_coo_remarks"
                                     :disabled="(
                                  !(currentActive[index - 1]?.name === activeBar?.name &&
                                  currentActive[index - 1]?.emp_id === item.r_coo_id) 
                                  || !item.gm_agm_status
                              )"
                            >
                            </textarea>

                          <!-- :disabled="!currentActive?.some(c => c?.name === activeBar?.name && item.r_coo_id == c?.emp_id ) ||
                                     (!item.gm_agm_status)" -->
                        </td>

                        <td v-if="activeBar?.name == 'CHLO'">
                          <!-- {{ item.r_coo_status }} -->
                          <v-select
                          class="selectWidth"
                          :options="statusOptions"
                          label="name"
                          :clearable="false"
                          v-model="item.chlo_status"
                          :disabled="(
                                  !(currentActive[index - 1]?.name === activeBar?.name &&
                                  currentActive[index - 1]?.emp_id === activeBar?.emp_id) 
                                  || !item.r_coo_status
                              )"
                          >

                          </v-select>
                        </td>
                        <td v-if="activeBar?.name == 'CHLO'">
                          <textarea
                              v-if="item.chlo_status?.value ==3 || item.chlo_status?.value == 4"
                              v-model="item.chlo_remarks"
                              :disabled="(
                                  (currentActive[index - 1]?.name === activeBar?.name &&
                                  currentActive[index - 1]?.emp_id === activeBar?.emp_id) 
                                  || !item.r_coo_status
                              )"
                            >
                            </textarea>
                        </td>

                        <!-- {{ !item.r_coo_status }} -->
                          <!-- {{ currentActive  }}
                            :disabled="!currentActive?.some(c => c?.name === activeBar?.name && c?.emp_id === activeBar?.emp_id) || 
                            (!item.r_coo_status)"
                          {{ 'je  ' + JSON.stringify(activeBar) }} -->

                        <td>
                          <!-- HR REJECT -->
                          <textarea
                          disabled
                          >
                            </textarea>
                        </td>

                        <td>
                          <router-link
                            :to="{
                              name: 'grading-evaluation-management-update',
                              params: {
                                id: item.grading_evaluation_id,
                              },
                            }"
                          >
                            <button
                              type="button"
                              class="btn btn-xs btn-blue waves-effect waves-light mb-2"
                            >
                              <i class="fas fa-eye"></i>
                              View
                            </button>
                          </router-link>
                        </td>
                        </tr>
                      
                    </tbody>
                    
                    </table>
                    </div>

                  </div>

                  <div
                class="progress mt-2"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="mt-3">
                <div class="d-flex align-items-center">
                  <p class="fw-bold mt-2">
                    {{ activeBar?.name }}
                  </p>
                  <div class="mx-4">
                    <div class="border p-2 rounded " style="min-width: 200px;">
                        {{ activeBar?.name === 'Related COO' ? relatedCooNames?.join(', ') : activeBar?.emp_name }}
                    </div>
                  </div>
                  <div>
                    <button class="buttonStyle bg-success" disabled>
                      Approved By
                    </button>
                  </div>
                </div>
              </div>

                <div
                  class="row mt-3"
                  v-if="
                  (activeBar?.name === 'Related COO' && currentActive?.some(c => c?.name == activeBar?.name && relatedCooIds?.includes(c?.emp_id) )) ||
                  currentActive?.find(c => c?.name == activeBar?.name && c?.emp_id == activeBar?.emp_id)
                  "
                  >
                  <!-- v-if="(activeBar?.name === 'Related COO' && currentActive?.name === 'Related COO' && relatedCooIds?.includes(currentActive?.emp_id)) || (activeBar?.name === currentActive?.name && activeBar?.emp_id === currentActive?.emp_id)" -->

                  <div class="col-md-12">
                    <div class="text-center mt-3 mb-3">
                    
                    <router-link :to="{ name: 'increment-management-approval' }" class="me-3">
                      <button type="button" class="btn w-sm btn-secondary">
                        Cancel
                      </button>
                    </router-link>

                      <button
                        :disabled="isLoading ? true : false"
                        type="button"
                        class="btn w-sm btn-success waves-effect waves-light px-3"
                        @click="updateConfirmStatus()"
                      >
                        <span
                          v-if="isLoading"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {{ isLoading == true ? "Loading..." : "Submit" }}
                      </button>
                    </div>
                  </div>
                </div>

              </form>

            </div>
        </div>
        </div>
        </div>
        </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import validationErrors from "../../../mixins/validationErrors";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors],
  data() {
    return {
      requestAssessors: [
        "Program Officer",
        "Department Head",
        "GM AGM",
        "Related COO",
        "CHLO"
      ],
      statusOptions: [
        {value: 1, name: "Approve"},
        {value: 2, name: "Reject"},
        {value: 3, name: "Resign"},
        {value: 4, name: "Termination"}
      ],
      relatedCooNames: [],
      relatedCooIds: [],
      activeBar: {},
      currentActive: {},
      assessorDetails: [],
      assessorList: [],
      employeeLists: [],
      programDetail: {},
      dataList: [],
      isLoading: false,
      isLoadingCancel: false,
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    async getApprovalData() {
        this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/increment-management-request-approve/${this.$route.params.id}`
        )
        .then(async (response) => {
          if (!response?.data?.data) return;

          response?.data?.data?.incrementManagement?.forEach((data) => {
            this.programDetail = {
              program_name: data.grading_evaluations?.program?.code,
              batch_name: data.grading_evaluations?.batch?.batch_no,
              level: data.grading_evaluations.level,
              effective_date: data.effective_date
            }

            const dataList = {
              id: data.id,
              grading_evaluation_id: data.grading_evaluation_id,
              emp_id: data.grading_evaluations.employee_id,
              emp_name: data.grading_evaluations.employee.name,
              joined_date: data.grading_evaluations.batch.join_date,
              bubrdiv_name: data.grading_evaluations.employee?.business_unit?.name,
              grade: data.grading_evaluations.grade,
              p_o_id: data.p_o_id,
              p_o_status: this.calculateStatus(data.p_o_status),
              p_o_remarks: data.p_o_remarks,
              d_h_id: data.d_h_id,
              d_h_status: this.calculateStatus(data.d_h_status),
              d_h_remarks: data.d_h_remarks,
              gm_agm_id: data.gm_agm_id,
              gm_agm_status: this.calculateStatus(data.gm_agm_status),
              gm_agm_remarks: data.gm_agm_remarks,
              r_coo_id: data.r_coo_id,
              r_coo_status: this.calculateStatus(data.r_coo_status),
              r_coo_remarks: data.r_coo_remarks,
              chlo_id: data.chlo_id,
              chlo_status: this.calculateStatus(data.chlo_status),
              chlo_remarks: data.chlo_remarks,
              current_submit_assessor_status: data.current_submit_assessor_status,
              current_submit_assessor: data.current_submit_assessor
            }

            this.assessorList = [
              {
                name: "Program Officer",
                emp_id: data.p_o_id,
                emp_name: data.program_officer?.name,
                is_active: data.current_submit_assessor == "Program Officer"
              },
              {
                name: "Department Head",
                emp_id: data.d_h_id,
                emp_name: data.department_head?.name,
                is_active: data.current_submit_assessor == "Department Head"
              },
              {
                name: "GM AGM",
                emp_id: data.gm_agm_id,
                emp_name: data.gm_agm?.name,
                is_active: data.current_submit_assessor == "GM AGM"
              },
              {
                name: "Related COO",
                emp_id: data.r_coo_id,
                emp_name: data.related_coo?.name,
                is_active: data.current_submit_assessor == "Related COO"
              },
              {
                name: "CHLO",
                emp_id: data.chlo_id,
                emp_name: data.chlo?.name,
                is_active: data.current_submit_assessor == "CHLO"
              },
            ]
            this.relatedCooNames?.push(data.related_coo?.name);
            this.relatedCooIds?.push(data.r_coo_id)

            
            this.assessorDetails.push(dataList);
          });
          this.currentActive = response?.data?.data?.currentActive;
          // console.log(response.data.data)

if(this.currentActive && this.currentActive?.length > 0) {
  this.handleCurrentActive(this.currentActive);
}else {
  this.handleCurrentActive(this.assessorList[0])
}

          // console.log(response.data.data.currentActive, 'data')
        }).catch((error) => {
          console.log(error, 'erro')
          this.toast.error("Something went wrong.");
        });
        this.loading = false;
      this.$Progress.finish();
    },

    handleCurrentActive(value) {

      const data = value && value.length > 0 ? value[0] : value;
             
      this.assessorList = this.assessorList?.map(ass => {
        return {
          ...ass,
          is_active: ass.name === data?.name
        };
      });

      this.activeBar = this.assessorList?.find(ass => ass.name === data?.name);

    },

    calculateStatus(status) {
      if(status == 0) return null;
      else if(status == 1) return { value: 1, name: "Approve"}
      else if(status == 2) return { value: 2, name: "Reject"}
      else if(status == 3) return { value: 3, name: "Resign"}
      else if(status == 4) return { value: 4, name: "Terminate"}
    },

    async updateConfirmStatus() {

      const clonedArray = JSON.parse(JSON.stringify(this.assessorDetails));
      clonedArray?.forEach(item => {
        if(this.activeBar?.name == 'Program Officer' && (item.d_h_status?.value == 2 || !item.d_h_status)) {
          if(item.p_o_status?.value == 1) {
            item.d_h_status = null;
            item.d_h_remarks = null;
            item.gm_agm_status = null;
            item.gm_agm_remarks = null;
            item.r_coo_status = null;
            item.r_coo_remarks = null;
            item.chlo_status = null;
            item.chlo_remarks = null;
          } else if(item.p_o_status?.value == 3 || item.p_o_status?.value == 4) {
            item.d_h_status = item.p_o_status;
            item.d_h_remarks = item.p_o_remarks;
            item.gm_agm_status = item.p_o_status;
            item.gm_agm_remarks = item.p_o_remarks;
            item.r_coo_status = item.p_o_status;
            item.r_coo_remarks = item.p_o_remarks;
            item.chlo_status = item.p_o_status;
            item.chlo_remarks = item.p_o_remarks;
          }
        }else if(this.activeBar?.name == 'Department Head' && (item.gm_agm_status?.value == 2 || !item.gm_agm_status)) {
          if(item.d_h_status?.value == 1) {
            item.gm_agm_status = null;  
            item.gm_agm_remarks = null;  
            item.r_coo_status = null;
            item.r_coo_remarks = null;
            item.chlo_status = null;
            item.chlo_remarks = null;
          } else if(item.d_h_status?.value == 2) {
            item.p_o_status = null;
            item.p_o_remarks = null;
          } else if(item.d_h_status?.value == 3 || item.d_h_status?.value == 4) {
            item.p_o_status = item.d_h_status;
            item.p_o_remarks = item.d_h_remarks;
            item.gm_agm_status = item.d_h_status;
            item.gm_agm_remarks = item.d_h_remarks;
            item.r_coo_status = item.d_h_status;
            item.r_coo_remarks = item.d_h_remarks;
            item.chlo_status = item.d_h_status;
            item.chlo_remarks = item.d_h_remarks;
          }
        }else if(this.activeBar?.name == 'GM AGM') {
          if(item.gm_agm_status?.value == 1 && (item.r_coo_status?.value == 2 || !item.r_coo_status)) {
            item.r_coo_status = null;
            item.r_coo_remarks = null;
            item.chlo_status = null;
            item.chlo_remarks = null;
          } else if(item.gm_agm_status?.value == 2) {
            item.p_o_status = null;
            item.p_o_remarks = null;
            item.d_h_status = null;
            item.d_h_remarks = null;
          } else if(item.gm_agm_status?.value == 3 || item.gm_agm_status?.value == 4 ) {
            item.p_o_status = item.gm_agm_status;
            item.p_o_remarks = item.gm_agm_remarks;
            item.d_h_status = item.gm_agm_status;
            item.d_h_remarks = item.gm_agm_remarks;
            item.r_coo_status = item.gm_agm_status;
            item.r_coo_remarks = item.gm_agm_remarks;
            item.chlo_status = item.gm_agm_status;
            item.chlo_remarks = item.gm_agm_remarks;
          }
        }else if(this.activeBar?.name == 'Related COO') {
          if(item.r_coo_status?.value == 1 && (item.chlo_status?.value == 2 || !item.chlo_status)) {
            item.chlo_status = null;
            item.chlo_remarks = null;
          } else if(item.r_coo_status?.value == 2) {
            item.gm_agm_status = null;
            item.gm_agm_remarks = null;
          }  else if(item.r_coo_status?.value == 3 || item.r_coo_status?.value == 4) {
            item.p_o_status = item.r_coo_status;
            item.p_o_remarks = item.r_coo_remarks;
            item.d_h_status = item.r_coo_status;
            item.d_h_remarks = item.r_coo_remarks;
            item.gm_agm_status = item.r_coo_status;
              item.gm_agm_remarks = item.r_coo_remarks;
            item.chlo_status = item.r_coo_status;
            item.chlo_remarks = item.r_coo_remarks;
          }
        }else if(this.activeBar?.name == 'CHLO') {
           if(item.chlo_status?.value == 2) {
            item.p_o_status = null;
            item.p_o_remarks = null;
            item.d_h_status = null;
            item.d_h_remarks = null;
            item.gm_agm_status = null;
            item.gm_agm_remarks = null;
            item.r_coo_status = null;
            item.r_coo_remarks = null;
          } else if(item.chlo_status?.value == 3 || item.chlo_status?.value == 4) {
            item.p_o_status = item.chlo_status;
            item.p_o_remarks = item.chlo_remarks;
            item.d_h_status = item.chlo_status;
            item.d_h_remarks = item.chlo_remarks;
            item.gm_agm_status = item.chlo_status;
            item.gm_agm_remarks = item.chlo_remarks;
            item.r_coo_status = item.chlo_status;
            item.r_coo_remarks = item.chlo_remarks;
          }
        }
      });

     this.isLoading = true;
      await axios
        .post(
          `${this.baseUrl}admin/v2/increment-management-confirm`,
          clonedArray
        )
        .then((response) => {
          this.toast.success("Confirmed Approval Successfully.");
          this.isLoading = false;
          this.$router.push({ name: "increment-management-approval" });
        })
        .catch((error) => {
          // console.log(error);
          this.isLoading = false;
          this.toast.error("Something went wrong!");
        });
    },
    
    getEmployee() {
      this.loading = true;
      this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
      if (this.$store.getters["odoo/getAllEmployees"].length == 0) {
        setTimeout(() => {
          this.getEmployee();
        }, 3000);
      } else {
        this.getApprovalData();
      }
    },
  },
  created() {
    this.getEmployee();
  },
}
</script>
<style scoped>
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: unset;
  border-style: unset;
  border-width: unset;
}
.title {
  cursor: pointer;
  user-select: none;
}
.active-title {
  position: relative;
}
.active-title::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background-color: blue;
}
.buttonStyle {
  padding: 0.5em;
  /* background-color: gray; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  border: none;
}
.overflowTable {
  min-width: 900px;
  /* overflow-x: auto; */
}
.controllingParent {
  overflow-x: auto;
  min-height: 40vh;
}
.selectWidth {
  width: 140px;
}
</style>